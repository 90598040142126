import React from 'react'
import Layout from '../components/layout'
import { Jumbotron, Button } from 'reactstrap';
import Header from '../components/header';

const NotFoundPage = () => (
  <Layout>
    <Header searchHide={true} />
    <Jumbotron className='text-center' style={{background: 'none', paddingTop: "100px"}}>
        <h1 className="display-4 font-weight-bold" style={{fontSize: "80px"}}>404</h1>
        <h3 className='text-black-50 py-4'>OOPS, SORRY WE CAN'T FIND THAT PAGE!</h3>
        <p className='pb-4' style={{fontSize: "15px"}}>Something went wrong or the page doesn't exist anymore</p>
        <hr className="my-2 py-4" />
        <p className="lead">
          <Button color="primary"><a style={{color: "white", textDecoration: "none"}} href="/">Home Page</a></Button>
        </p>
      </Jumbotron>

  </Layout>
)

export default NotFoundPage

